
import Vue from 'vue'

export default Vue.extend({
  name: 'ToggleButton',
  model: {
    prop: 'checked',
    event: 'checked',
  },
  props: {
    checked: {
      type: Boolean,
    },
    visualOnly: {
      type: Boolean,
    },
    activeColor: {
      type: String,
      default: 'text-theme-blue',
    },
    bgColor: {
      type: String,
      default: 'bg-theme',
    },
  },
  computed: {
    toggleSize(): string {
      return this.checked ? 'calc(0.625em + 2px)' : '0.625em'
    },
  },
  methods: {
    click() {
      if (this.visualOnly) return
      this.$emit('checked', !this.checked)
    },
  },
})
