
import Vue from 'vue'
import { siteUserPageUrl } from '~/assets/ts/utils/urls'
import SiteResizablePromo from '~/components/site/ResizablePromo.vue'

export default Vue.extend({
  name: 'SiteUserPromo',
  components: { SiteResizablePromo },
  methods: { siteUserPageUrl },
})
