
import Vue from 'vue'

export default Vue.extend({
  name: 'PagerDots',
  model: {
    prop: 'page',
    event: 'change',
  },
  props: {
    page: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    activeClass: {
      type: String,
      default: 'text-theme-blue',
    },
    inactiveClass: {
      type: String,
      default:
        'text-gray-300 dark:text-gray-600 hover:text-gray dark:hover:text-gray-400',
    },
  },
  methods: {
    setPage(page: number) {
      this.$emit('change', page)
    },
  },
})
