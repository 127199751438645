
import Vue, { PropType } from 'vue'
import { Webcast } from '~/models/webcast'
import { PlayerMediaType, WebcastDisplay } from '~/assets/ts/enums'
import { siteWebcastUrl } from '~/assets/ts/utils/urls'
import BackgroundImage from '~/components/_general/BackgroundImage.vue'
import SiteLayoutWithSideContent from '~/components/site/layout/WithSideContent.vue'
import PlayerElement from '~/components/player/Element.vue'
import SiteHomeHeroSide from '~/components/site/home/HeroSide.vue'
import WebcastMetadata from '~/components/_general/WebcastMetadata.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'

export default Vue.extend({
  name: 'SiteHomeBoostedWebcast',
  components: {
    LoadingElement,
    InlineIcon,
    WebcastMetadata,
    SiteHomeHeroSide,
    PlayerElement,
    SiteLayoutWithSideContent,
    BackgroundImage,
  },
  props: {
    webcast: {
      type: Object as PropType<Webcast>,
      default: undefined,
    },
  },
  data() {
    return {
      mounted: false,
    }
  },
  computed: {
    webcastDisplay(): WebcastDisplay {
      return WebcastDisplay.LiveVideo
    },
    videoMediaType(): PlayerMediaType {
      return PlayerMediaType.Video
    },
    webcastImage(): string {
      return this.webcast?.ResizableImage(96) ?? ''
    },
    title(): string {
      return this.webcast?.Title(this) ?? ''
    },
    description(): string {
      return this.webcast?.description ?? ''
    },
    url(): string {
      return siteWebcastUrl(this.webcast?.broadcasterID)
    },
    viewersCount(): number {
      return this.webcast?.totalTuneInCount ?? 0
    },
  },
  mounted() {
    this.mounted = true
  },
})
