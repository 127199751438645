
import Vue from 'vue'
import { metadata } from '~/assets/ts/utils/metadata'
import { Sermon } from '~/models/sermon'
import {
  SeriesDisplay,
  SermonDisplay,
  SermonMetadataDisplay,
} from '~/assets/ts/enums'
import { CommentSortOptions, UserCommentApi } from '~/apiclient/apicomments'
import { UserComment } from '~/models/usercomment'
import { getListWithPlaceholders } from '~/assets/ts/utils/lists'
import { QuoteApi } from '~/apiclient/apidevotional'
import {
  seriesRootUrl,
  sermonRootUrl,
  siteUserPageUrl,
} from '~/assets/ts/utils/urls'
import { SeriesRequestOptions } from '~/apiclient/apiseries'
import { WebcastApi } from '~/apiclient/apiwebcasts'
import { Webcast } from '~/models/webcast'
import { SermonRequestOptions, SermonSortOptions } from '~/apiclient/apisermons'
import { EventTypeApi } from '~/apiclient/apifilters'
import { qsBool } from '~/assets/ts/utils/params'
import { SiteAlertModal } from '~/assets/ts/utils/site'
import SiteHomeBoostedWebcast from '~/components/site/home/BoostedWebcast.vue'
import Poller from '~/components/_general/Poller.vue'
import SiteHomeHero from '~/components/site/home/hero.vue'
import SiteLayoutWithPromo from '~/components/site/layout/WithPromo.vue'
import SiteTitledSection from '~/components/site/TitledSection.vue'
import SermonElement from '~/components/_general/SermonElement.vue'
import SiteUserPromo from '~/components/site/user/Promo.vue'
import SiteFeaturedCommentElement from '~/components/site/featured/CommentElement.vue'
import SiteSermonsFilteredListWithTitle from '~/components/site/sermons/FilteredListWithTitle.vue'
import SiteSeriesFilteredListWithTitle from '~/components/site/series/FilteredListWithTitle.vue'
import SiteSermonsCategoryElement from '~/components/site/sermons/CategoryElement.vue'
import SiteFeaturedQuoteElement from '~/components/site/featured/QuoteElement.vue'
import SiteUserWelcomeModal from '~/components/site/user/WelcomeModal.vue'
import DelayRender from '~/components/_general/DelayRender.vue'
import SiteSermonListWithTitle from '~/components/site/SermonListWithTitle.vue'
import { LivePollerSiteFrequency } from '~/assets/ts/utils/webcasts'
import HorizontalSermonList from '~/components/_general/HorizontalSermonList.vue'
import SiteResizablePromo from '~/components/site/ResizablePromo.vue'
import SiteRecentStat from '~/components/site/SiteRecentStat.vue'
import { SaStatsResults } from '~/apiclient/apimisc'

export default Vue.extend({
  components: {
    SiteRecentStat,
    SiteResizablePromo,
    HorizontalSermonList,
    SiteSermonListWithTitle,
    DelayRender,
    SiteFeaturedQuoteElement,
    SiteSermonsCategoryElement,
    SiteSeriesFilteredListWithTitle,
    SiteSermonsFilteredListWithTitle,
    SiteFeaturedCommentElement,
    SiteUserPromo,
    SermonElement,
    SiteTitledSection,
    SiteLayoutWithPromo,
    SiteHomeHero,
    Poller,
    SiteHomeBoostedWebcast,
  },
  layout: 'site',
  middleware: ['fetchBoostedWebcasts', 'fetch/displayAds'],
  data() {
    return {
      featuredSermonsPojos: [] as Record<string, any>[],
      staffPickPojos: [] as Record<string, any>[],
      newestVideosPojo: [] as Record<string, any>[],
      quoteOfTheDay: undefined as QuoteApi | undefined,
      commentPojo: undefined as UserCommentApi | undefined,
      popularEvents: [] as EventTypeApi[],
    }
  },
  async fetch() {
    await this.getFeaturedSermons()
  },
  head() {
    return metadata(this, {
      title: 'SermonAudio',
    })
  },
  computed: {
    boostedWebcastFrequency(): number {
      return LivePollerSiteFrequency
    },
    seriesPicksOptions(): Partial<SeriesRequestOptions> {
      return {
        staffPicks: true,
        pageSize: 6,
        sort_by: 'random',
      }
    },
    recentSeriesOptions(): Partial<SeriesRequestOptions> {
      return {
        pageSize: 6,
        sort_by: 'last_updated',
        onlyWithAlbumArt: true,
      }
    },
    newestSermonOptions(): SermonRequestOptions {
      return {
        pageSize: 5,
        sortBy: SermonSortOptions.NewestPublished,
      }
    },
    sermonListsLength(): number {
      return 6
    },
    SeriesDisplay() {
      return SeriesDisplay
    },
    SermonMetadataDisplay() {
      return SermonMetadataDisplay
    },
    SermonDisplay() {
      return SermonDisplay
    },
    staffPicks(): Sermon[] {
      if (!this.staffPickPojos.length) return []
      return this.staffPickPojos.map((s: Record<string, any>) => new Sermon(s))
    },
    staffPick(): Sermon | undefined {
      return this.staffPicks.length ? this.staffPicks[0] : undefined
    },
    previousStaffPicks(): Sermon[] {
      const l = this.staffPicks.length
      const list = l > 1 ? this.staffPicks.slice(1, l) : []
      return getListWithPlaceholders(list, this.sermonListsLength - 1)
    },
    newestVideos(): Sermon[] | undefined[] {
      return getListWithPlaceholders(
        this.newestVideosPojo.map((s: Record<string, any>) => new Sermon(s)),
        this.sermonListsLength
      )
    },
    featuredSermons(): Sermon[] {
      if (!this.featuredSermonsPojos.length) return []
      return this.featuredSermonsPojos.map(
        (s: Record<string, any>) => new Sermon(s)
      )
    },
    featuredSermon(): Sermon | undefined {
      return this.featuredSermons.length ? this.featuredSermons[0] : undefined
    },
    previouslyFeatured(): Sermon[] {
      const l = this.featuredSermons.length
      const list = l > 1 ? this.featuredSermons.slice(1, l) : []
      return getListWithPlaceholders(list, this.sermonListsLength - 1)
    },
    comment(): UserComment | undefined {
      return this.commentPojo ? new UserComment(this.commentPojo) : undefined
    },
    events(): EventTypeApi[] {
      return this.$store.getters['filters/events']
    },
    allPopularEvents(): EventTypeApi[] {
      return this.events.filter((event) => event.popular)
    },
    newestSermonsUrl(): string {
      return sermonRootUrl
    },
    recentSeriesUrl(): string {
      return `${seriesRootUrl}recent`
    },
    newestVideosUrl(): string {
      return `${sermonRootUrl}/videos`
    },
    seriesPicksUrl(): string {
      return `${seriesRootUrl}picks`
    },
    sermonCategoriesUrl(): string {
      return `${sermonRootUrl}/categories`
    },
    staffPicksUrl(): string {
      return `${sermonRootUrl}/staff-picks`
    },
    featuredUrl(): string {
      return `${sermonRootUrl}/featured`
    },
    boostedWebcastApi(): WebcastApi | undefined {
      return this.$store.getters['webcasts/boostedWebcast']
    },
    boostedWebcast(): Webcast | undefined {
      return this.boostedWebcastApi
        ? new Webcast(this.boostedWebcastApi)
        : undefined
    },
    showBoosted(): boolean {
      return !!this.boostedWebcast
    },
    loggedIn(): boolean {
      return this.$users.loggedIn
    },
    userSettingsUrl(): string {
      return `${siteUserPageUrl('settings')}?newsletter=true`
    },
    saStats(): SaStatsResults {
      return this.$store.getters['misc/stats']
    },
    newsletterSubs(): number | undefined {
      return this.saStats.newsletterSubscriberCount
    },
    speakerTotal(): number | undefined {
      return this.saStats.activeSpeakerCount
    },
    speakerRecent(): number | undefined {
      return this.saStats.activeSpeakerCountLast30Days
    },
    sermonsTotal(): number | undefined {
      return this.saStats.sermonCount
    },
    sermonsRecent(): number | undefined {
      return this.saStats.sermonCountLast30Days
    },
    playsTotal(): number | undefined {
      return this.saStats.totalSermonPlays
    },
    playsRecent(): number | undefined {
      return this.saStats.totalSermonPlaysLast30Days
    },
  },
  async mounted() {
    this.showWelcome()
    // this.getFeaturedSermons()
    await this.getStats()
  },
  methods: {
    showWelcome() {
      if (!qsBool(this, 'welcome')) return
      if (!this.loggedIn) return
      SiteAlertModal(this, {
        title: this.$t('Welcome to SermonAudio!'),
        text: this.$t(
          "You're now signed into your new SermonAudio user account! Here are some things you can do to get started."
        ),
        component: SiteUserWelcomeModal,
        maxWidth: 960,
      })
    },
    async getEvents() {
      await this.$store.dispatch('filters/getEvents')
      this.popularEvents = [...this.allPopularEvents]
        .sort(() => 0.5 - Math.random())
        .slice(0, 6)
    },
    getBoostedWebcasts() {
      this.$store.dispatch('webcasts/fetchBoostedWebcasts')
    },
    async getStaffPicks(): Promise<void> {
      const { results } = await this.$apiClient.getFilteredSermonList({
        pageSize: this.sermonListsLength,
        staffPick: true,
      })
      this.staffPickPojos = results
    },
    async getNewestVideos(): Promise<void> {
      const { results } = await this.$apiClient.getFilteredSermonList({
        pageSize: 12,
        requireVideo: true,
        sortBy: SermonSortOptions.NewestPublished,
      })

      this.newestVideosPojo = results.map((s: Record<string, any>) => s)
    },
    async getFeaturedSermons(): Promise<void> {
      const { results } = await this.$apiClient.getFilteredSermonList({
        page: 1,
        pageSize: this.sermonListsLength,
        featured: true,
        // TODO: remove cache override once moved to Postgres and bug fixed
        cache: false,
        cacheTTL: 3600,
        lite: true,
      })
      this.featuredSermonsPojos = results
    },
    async getQuote(): Promise<void> {
      this.quoteOfTheDay = await this.$apiClient.getQuote()
    },
    async getStats() {
      await this.$store.dispatch('misc/getSaStats')
    },
    async getComment(): Promise<void> {
      const { results } = await this.$apiClient.getFilteredComments({
        pageSize: 1,
        sortBy: CommentSortOptions.Random,
        recent: true,
      })
      this.commentPojo = results.pop()
    },
  },
})
