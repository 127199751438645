
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { EventTypeApi } from '~/apiclient/apifilters'
import { SermonRequestOptions } from '~/apiclient/apisermons'
import { sermonRootUrl, siteSeriesUrl } from '~/assets/ts/utils/urls'
import SaIcon from '~/components/_general/SaIcon.vue'
import { SaIconsType } from '~/assets/ts/types/icons'
import HorizontalFilteredSermonList from '~/components/_general/HorizontalFilteredSermonList.vue'
import { Series } from '~/models/series'
import LoadingElement from '~/components/_general/LoadingElement.vue'
import SaLink from '~/components/_general/SaLink.vue'

type CategoryType = 'event' | 'series'

export default Vue.extend({
  name: 'SiteSermonsCategoryElement',
  components: {
    LoadingElement,
    HorizontalFilteredSermonList,
    SaIcon,
  },
  props: {
    event: {
      type: Object as PropType<EventTypeApi>,
      default: undefined,
    },
    series: {
      type: Object as PropType<Series>,
      default: undefined,
    },
    categoryType: {
      type: String as PropType<CategoryType>,
      default: 'event',
    },
    showSermons: {
      type: Boolean,
    },
    delayRender: {
      type: Boolean,
    },
  },
  computed: {
    SaLink() {
      return SaLink
    },
    options(): SermonRequestOptions | undefined {
      if (this.isSeries && !this.series) return undefined
      const options = {
        requireVideo: true,
      } as SermonRequestOptions
      if (this.event) {
        options.eventType = this.event.description
      } else if (this.series) {
        options.series = this.series.id.toString()
      }
      return options
    },
    iconSize(): string {
      return this.isSeries ? 'text-3xl' : 'text-2xl'
    },
    isSeries(): boolean {
      return this.categoryType === 'series'
    },
    eventIcon(): SaIconsType {
      switch (this.event?.eventImage) {
        case 'audio_book.png':
          return 'audio_book-categories'
        case 'bible_study.png':
          return 'bible_study-categories'
        case 'campground.png':
          return 'campground-categories'
        case 'podium.png':
          return 'podium-categories'
        case 'smile.png':
          return 'smile-categories'
        case 'cassette.png':
          return 'cassette-categories'
        case 'users.png':
          return 'users-categories'
        case 'newspaper.png':
          return 'newspaper-categories'
        case 'debate.png':
          return 'debate-categories'
        case 'coffee.png':
          return 'coffee-categories'
        case 'flower_tulip.png':
          return 'flower_tulip-categories'
        case 'calendar.png':
          return 'calendar-categories'
        case 'podcast.png':
          return 'podcast-categories'
        case 'praying_hands.png':
          return 'praying_hands-categories'
        case 'comment_question.png':
          return 'comment_question-categories'
        case 'comments.png':
          return 'comments-categories'
        case 'radio_event.png':
          return 'radio_event-categories'
        case 'cut.png':
          return 'cut-categories'
        case 'star.png':
          return 'star-categories'
        case 'sunrise.png':
          return 'sunrise-categories'
        case 'sunset.png':
          return 'sunset-categories'
        case 'sun.png':
          return 'sun-categories'
        case 'chalkboard_teacher.png':
          return 'chalkboard_teacher-categories'
        case 'church.png':
          return 'church-categories'
        case 'graduation_cap.png':
          return 'graduation_cap-categories'
        case 'user_chat.png':
          return 'user_chat-categories'
        case 'tv.png':
          return 'tv-categories'
        case 'disc_drive.png':
          return 'disc_drive-categories'
        case 'rings_wedding.png':
          return 'rings_wedding-categories'
        case 'child.png':
          return 'child-categories'
        default:
          return 'sa-icon-centered-brands'
      }
    },
    sermonCount(): TranslateResult {
      const count = this.event?.numberOfSermons ?? this.series?.count ?? 0
      if (!count) return ''
      return this.$tc('{n} Sermon | {n} Sermons', count, {
        n: count.toLocaleString(),
      })
    },
    url(): string {
      if (this.isSeries) return siteSeriesUrl(this.series)
      return `${sermonRootUrl}/categories/${this.eventDesc}`
    },
    eventDesc(): string {
      return this.event?.description ?? ''
    },
    title(): string {
      if (this.series) return this.series.title
      return this.event?.displayEventType ?? ''
    },
  },
})
