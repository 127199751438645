
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import SearchInput from '~/components/_general/SearchInput.vue'
import { FancyDropdownOption } from '~/components/_general/FancyDropdown.vue'

export default Vue.extend({
  name: 'FancyDropdownSearchBar',
  components: { SearchInput },
  model: {
    prop: 'input',
    event: 'search',
  },
  props: {
    placeholder: {
      type: String as PropType<TranslateResult>,
      default(): TranslateResult {
        return this.$t('Search')
      },
    },
    searching: {
      type: Boolean,
    },
    input: {
      type: String,
      required: true,
    },
    results: {
      type: Array as PropType<FancyDropdownOption[]>,
      required: true,
    },
  },
  mounted() {
    this.$on('focus', this.focus)
  },
  destroyed() {
    this.$off('focus', this.focus)
  },
  methods: {
    focus() {
      const el = this.$refs.search as Vue
      el.$emit('focus')
    },
    search(value: string) {
      this.$emit('search', value)
    },
    setInput(value: string) {
      this.search(value)
    },
    clear() {
      this.search('')
    },
  },
})
