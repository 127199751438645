
import Vue from 'vue'
import ViewportObserver from '~/components/_general/ViewportObserver .vue'

export default Vue.extend({
  name: 'DelayRender',
  components: { ViewportObserver },
  props: {
    onMount: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    visibleBuffer(): number {
      return 180
    },
    renderBuffer(): number {
      return this.visibleBuffer + 60
    },
  },
})
