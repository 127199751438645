
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'
import { User } from '~/models/users/user'
import { siteUserPageUrl } from '~/assets/ts/utils/urls'
import SiteTitledSection from '~/components/site/TitledSection.vue'
import SaLink from '~/components/_general/SaLink.vue'
import SiteUserWelcomeOption from '~/components/site/user/WelcomeOption.vue'
import PagerDots from '~/components/_general/PagerDots.vue'
import SiteButton from '~/components/site/Button.vue'

export default Vue.extend({
  name: 'SiteUserWelcomeModal',
  components: {
    SiteButton,
    PagerDots,
    SiteUserWelcomeOption,
    SaLink,
    SiteTitledSection,
  },
  data() {
    return {
      page: 1,
    }
  },
  computed: {
    verify(): boolean {
      return this.page === 1
    },
    title(): TranslateResult {
      if (this.verify) return this.$t('Verify your Email Address')
      return this.$t('What you can do with your User Account')
    },
    user(): User | undefined {
      return this.$users.user
    },
    settingsUrl(): string {
      return siteUserPageUrl('settings')
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
})
