
import Vue, { PropType } from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import { SaIconsType } from '~/assets/ts/types/icons'
import SiteButton, { BaseButtonProps } from '~/components/site/Button.vue'

export default Vue.extend({
  name: 'IconCircleButton',
  components: { SiteButton, SaIcon },
  props: {
    icon: {
      type: String as PropType<SaIconsType>,
      required: true,
    },
    ...BaseButtonProps,
  },
  computed: {
    classes(): string {
      const base = '!px-0 flex-center'
      switch (this.size) {
        case 'small':
          return `${base} !text-sm w-7`
        case 'large':
          return `${base} !text-lg w-10`
        case 'normal':
        default:
          return `${base} !text-base w-8`
      }
    },
  },
})
