
import Vue from 'vue'
import { SaImageProps } from '~/components/_general/SaImage.vue'

export default Vue.extend({
  name: 'SaPicture',
  props: {
    ...SaImageProps,
    sizes: {
      type: String,
      required: true,
    },
  },
})
