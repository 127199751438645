
import Vue from 'vue'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'FancyDropdownBackButton',
  components: { SaIcon },
  props: {
    opened: {
      type: Boolean,
    },
  },
})
