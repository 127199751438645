
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import FancyDropdownWithPages, {
  FancyDropdownPage,
  FancyDropdownWithPageEvent,
  FancyDropdownWithPageSearch,
} from '~/components/fancyDropdown/WithPages.vue'
import {
  FiltersToSermonRequestOptions,
  GetChildCategory,
  SermonFilterCategories,
  SermonFilterCategoryTitle,
  SermonFilterSelection,
  ShouldSearchCategory,
} from '~/assets/ts/utils/params'
import { SermonRequestOptions } from '~/apiclient/apisermons'
import {
  CreateDurationFilterValue,
  DurationFilterTitle,
  FilterOptionParams,
  FilterOptions,
  GenericFilter,
  MediaFilterTitle,
  ParseMediaFilter,
} from '~/apiclient/apifilters'
import { getStrValuesFromEnum } from '~/assets/ts/enums'
import { FancyDropdownOption } from '~/components/_general/FancyDropdown.vue'
import { Broadcaster } from '~/models/broadcaster'
import { resizableUrl } from '~/assets/ts/utils/misc'
import { Speaker } from '~/models/speaker'
import { ValuesMatch } from '~/assets/ts/utils/validation'

export default Vue.extend({
  name: 'FilterSermonsDropdown',
  components: { FancyDropdownWithPages },
  inheritAttrs: false,
  props: {
    filters: {
      type: Array as PropType<SermonFilterSelection[]>,
      default: () => [],
    },
    sermonOptions: {
      type: Object as PropType<SermonRequestOptions>,
      required: true,
    },
  },
  data() {
    return {
      options: undefined as FilterOptions | undefined,
      apiSearch: undefined as FancyDropdownWithPageSearch | undefined,
      searching: false,
    }
  },
  computed: {
    imageWidth(): number {
      return 32
    },
    broadcasterSelected(): boolean {
      return !!this.filterOptionParams.broadcasterID
    },
    pages(): FancyDropdownPage[] {
      const pages = [] as FancyDropdownPage[]
      if (!this.options) return pages
      this.createCategoryPage(
        pages,
        SermonFilterCategories.Broadcaster,
        this.broadcasterOptions(),
        true
      )
      this.createCategoryPage(
        pages,
        SermonFilterCategories.Event,
        this.genericOptions(this.options.categories)
      )
      if (!this.broadcasterSelected) {
        this.createCategoryPage(
          pages,
          SermonFilterCategories.Denomination,
          this.genericOptions(this.options.denominations).filter(
            (d) => d.value !== '(none)'
          )
        )
      }
      this.createCategoryPage(
        pages,
        SermonFilterCategories.Duration,
        this.durationOptions(),
        false,
        -1
      )
      this.createCategoryPage(
        pages,
        SermonFilterCategories.Language,
        this.languageOptions()
      )
      this.createCategoryPage(
        pages,
        SermonFilterCategories.Media,
        this.mediaOptions(),
        false,
        -1
      )
      const scripture = this.scriptureOptions()
      this.createCategoryPage(
        pages,
        SermonFilterCategories.Book,
        scripture,
        false
      )
      scripture.forEach((book) => {
        this.createCategoryPage(
          pages,
          SermonFilterCategories.Chapter,
          this.chapterOptions(book.value),
          false,
          undefined,
          book.value
        )
      })
      this.createCategoryPage(
        pages,
        SermonFilterCategories.Series,
        this.seriesOptions(),
        true
      )
      this.createCategoryPage(
        pages,
        SermonFilterCategories.Speaker,
        this.speakerOptions(),
        true
      )
      this.createCategoryPage(
        pages,
        SermonFilterCategories.Year,
        this.genericOptions(this.options.years),
        false,
        0
      )
      return pages
    },
    filterOptionParams(): FilterOptionParams {
      return {
        ...FiltersToSermonRequestOptions(this.filters),
        ...this.searchParams,
        ...this.sermonOptions,
      }
    },
    searchParams(): FilterOptionParams {
      if (!this.apiSearch) return {}
      const category = this.apiSearch.page.id as SermonFilterCategories
      if (category === SermonFilterCategories.Broadcaster) {
        return {
          broadcasterNameFilter: this.apiSearch.query,
        }
      } else if (category === SermonFilterCategories.Series) {
        return {
          seriesNameFilter: this.apiSearch.query,
        }
      } else if (category === SermonFilterCategories.Speaker) {
        return {
          speakerNameFilter: this.apiSearch.query,
        }
      }
      return {}
    },
    availableCategories(): SermonFilterCategories[] {
      const cats = getStrValuesFromEnum(
        SermonFilterCategories
      ) as SermonFilterCategories[]
      return cats.filter((c) => !Object.keys(this.sermonOptions).includes(c))
    },
    placeholderCount(): number {
      return this.availableCategories.filter((c) => {
        return ![
          SermonFilterCategories.Search,
          SermonFilterCategories.Chapter,
        ].includes(c)
      }).length
    },
    dropdown(): Vue {
      return this.$refs.dropdown as Vue
    },
    disableMediaFilter(): boolean {
      return this.filters.some(
        (f) => f.category === SermonFilterCategories.Media
      )
    },
    disableScriptureFilter(): boolean {
      return this.filters.some((f) =>
        [SermonFilterCategories.Book, SermonFilterCategories.Chapter].includes(
          f.category
        )
      )
    },
  },
  watch: {
    filters() {
      const editing = this.filters.find((f) => f.editing)
      if (!editing) return
      this.dropdown.$emit('open', editing.category)
    },
    filterOptionParams: {
      handler(
        newParams: FilterOptionParams | undefined,
        oldParams: FilterOptionParams | undefined
      ) {
        if (ValuesMatch(newParams, oldParams)) return
        this.getFilterOptions()
      },
    },
  },
  methods: {
    async opened() {
      if (this.options) return
      await this.getFilterOptions()
    },
    change(newFilters: FancyDropdownWithPageEvent[]) {
      const ids = newFilters.map((f) => f.id)
      const filters = [...this.filters].filter((f) => !ids.includes(f.category))
      newFilters
        .filter((f) => !!f.option.value)
        .forEach((filter) => {
          filters.push({
            category: filter.id as SermonFilterCategories,
            value: filter.option.value,
            display: filter.option.title,
          })
        })
      this.$emit('change', filters)
    },
    async getFilterOptions() {
      this.searching = true
      this.options = await this.$apiClient.getFilterOptions(
        this.filterOptionParams
      )
      this.searching = false
    },
    createCategoryPage(
      pages: FancyDropdownPage[],
      category: SermonFilterCategories,
      options: FancyDropdownOption[],
      apiSearch: boolean = false,
      searchThreshold: number | undefined = undefined,
      parentId: string | undefined = undefined
    ): FancyDropdownPage | undefined {
      if (!this.availableCategories.includes(category)) return
      pages.push({
        id: category,
        title: SermonFilterCategoryTitle(this, category),
        searchable: searchThreshold !== -1,
        options,
        parentId,
        searchThreshold,
        apiSearch,
        childId: GetChildCategory(category),
      })
    },
    genericOptions(list: GenericFilter[]): FancyDropdownOption[] {
      return list.map((i) => {
        return {
          value: i.id,
          title: i.label,
          subtitle: this.toSermonCount(i.count),
        }
      })
    },
    languageOptions(): FancyDropdownOption[] {
      if (!this.options?.languages) return []
      return this.options.languages.map((l) => {
        return {
          title: l.localizedName,
          value: l.languageCode3,
          subtitle: this.toSermonCount(l.sermonCount),
        }
      })
    },
    broadcasterOptions(): FancyDropdownOption[] {
      if (!this.options?.broadcasters) return []
      return this.options.broadcasters.map((b) => {
        const broadcaster = new Broadcaster(b)
        return {
          title: broadcaster.displayName,
          value: broadcaster.id,
          subtitle: broadcaster.location,
          imageURL: broadcaster.imageResizable(this.imageWidth),
        }
      })
    },
    scriptureOptions(): FancyDropdownOption[] {
      if (!this.options?.books || this.disableScriptureFilter) return []
      return this.options.books.map((b) => {
        return {
          title: b.displayBookName,
          value: b.osisPA,
          subtitle: this.toSermonCount(b.count),
        }
      })
    },
    chapterOptions(bookId: string): FancyDropdownOption[] {
      const book = this.options?.books.find((b) => b.osisPA === bookId)
      if (!book) return []
      const chapters = book.chapters.map((n) => {
        const count = book.chapterCounts.find((c) => c[0] === n)
        return {
          title: this.$t('Chapter {n}', { n }),
          value: n.toString(),
          subtitle: count ? this.toSermonCount(count[1]) : undefined,
        }
      })
      return [
        {
          title: this.$t('All Chapters'),
          value: '',
          subtitle: this.toSermonCount(book.count),
        },
        ...chapters,
      ]
    },
    seriesOptions(): FancyDropdownOption[] {
      if (!this.options?.series) return []
      return this.options.series.map((s) => {
        const size = this.imageWidth
        const broadcaster = s.broadcaster.displayName
        return {
          title: s.label,
          value: s.id,
          subtitle: this.broadcasterSelected
            ? this.toSermonCount(s.count)
            : this.$t('by {broadcaster}', { broadcaster }),
          imageURL: s.imageResizable
            ? resizableUrl(s.imageResizable, size, size)
            : undefined,
        }
      })
    },
    speakerOptions(): FancyDropdownOption[] {
      if (!this.options?.speakers) return []
      return this.options.speakers.map((s) => {
        const speaker = new Speaker(s)
        return {
          title: speaker.displayName,
          value: speaker.id.toString(),
          imageURL: speaker.resizableImage(this.imageWidth),
          roundImage: true,
          subtitle: this.toSermonCount(s.sermonCount),
        }
      })
    },
    durationOptions(): FancyDropdownOption[] {
      if (!this.options?.durations) return []
      return this.options.durations.map((d) => {
        return {
          title: DurationFilterTitle(this, d),
          value: CreateDurationFilterValue(d),
          subtitle: this.toSermonCount(d.count),
        }
      })
    },
    mediaOptions(): FancyDropdownOption[] {
      if (!this.options?.mediaCounts || this.disableMediaFilter) return []
      return this.options.mediaCounts.map((d) => {
        return {
          title: MediaFilterTitle(this, ParseMediaFilter(d.label)),
          value: d.label,
          subtitle: this.toSermonCount(d.count),
        }
      })
    },
    toSermonCount(count: number): TranslateResult {
      return this.$tc('{n} Sermon | {n} Sermons', count, {
        n: count.toLocaleString(),
      })
    },
    searched(search: FancyDropdownWithPageSearch) {
      const category = search.page.id as SermonFilterCategories
      if (this.apiSearch || ShouldSearchCategory(category)) {
        this.apiSearch = search
      } else {
        this.apiSearch = undefined
      }
    },
    clearSearch() {
      this.apiSearch = undefined
    },
  },
})
