
import Vue from 'vue'
import { waitOneFrame } from '~/assets/ts/utils/misc'

export default Vue.extend({
  name: 'ViewportObserver',
  props: {
    alwaysRender: {
      type: Boolean,
    },
    checkOnMounted: {
      type: Boolean,
    },
    /** The pixels above the element when rendering begins */
    renderBuffer: {
      type: Number,
      default: 0,
    },
    /** The pixels above the element when visible is triggered */
    visibleBuffer: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      render: false,
      visible: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.checkPosition)
    if (this.checkOnMounted) {
      this.checkPosition()
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.checkPosition)
  },
  methods: {
    checkPosition() {
      const height = document.documentElement.clientHeight
      const pos = this.$el.getBoundingClientRect().top
      const render = pos < height + this.renderBuffer
      if (!render) return
      this.renderEvent()
      const visible = pos < height + this.visibleBuffer
      if (!visible) return
      this.visibleEvent()
    },
    renderEvent() {
      if (this.render) return
      this.$emit('render')
      this.render = true
    },
    async visibleEvent() {
      if (this.visible) return
      this.visible = true
      await waitOneFrame()
      this.$emit('visible')
      window.removeEventListener('scroll', this.checkPosition)
    },
  },
})
