
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { SaIconsType } from '~/assets/ts/types/icons'
import SaIcon from '~/components/_general/SaIcon.vue'

export default Vue.extend({
  name: 'SiteUserWelcomeOption',
  components: { SaIcon },
  props: {
    icon: {
      type: String as PropType<SaIconsType>,
      required: true,
    },
    title: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    description: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
  },
})
