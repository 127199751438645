
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import { getStrValuesFromEnum } from '~/assets/ts/enums'
import { FancyDropdownOption } from '~/components/_general/FancyDropdown.vue'
import { SermonSortOptions } from '~/apiclient/apisermons'
import { qsBool, qsValue } from '~/assets/ts/utils/params'
import SortDropdown from '~/components/sort/Dropdown.vue'

const sortKey = 'sort'

export enum SermonQuery {
  VideoOnly = 'video',
}

export function SermonSortQs(sort: SermonSortOptions) {
  return `${sortKey}=${sort}`
}

export function SermonSortQsPair(sort: SermonSortOptions) {
  return { key: sortKey, value: sort }
}

export function GetSermonVideoFromQs(context: Vue): boolean {
  return qsBool(context, SermonQuery.VideoOnly)
}

export function GetSermonSortFromQs(
  context: Record<any, any>,
  defaultSort: SermonSortOptions = SermonSortOptions.Newest
): SermonSortOptions {
  const qsSort = qsValue(context, sortKey) as SermonSortOptions
  const validQs = getStrValuesFromEnum(SermonSortOptions).includes(qsSort)
  return validQs ? qsSort : defaultSort
}

export default Vue.extend({
  name: 'SortSermonsElement',
  components: { SortDropdown },
  props: {
    sortBy: {
      type: String as PropType<SermonSortOptions>,
      default: SermonSortOptions.Updated,
    },
  },
  computed: {
    availableSortOptions(): SermonSortOptions[] {
      const options = [
        SermonSortOptions.Newest,
        SermonSortOptions.Oldest,
        SermonSortOptions.Updated,
        SermonSortOptions.Title,
        SermonSortOptions.Speaker,
        SermonSortOptions.LastPlayed,
        SermonSortOptions.Downloads,
        SermonSortOptions.Relevance,
        SermonSortOptions.Bible,
      ]
      if (!options.includes(this.sortBy)) {
        return [...options, this.sortBy]
      }
      return options
    },
    options(): FancyDropdownOption[] {
      return this.availableSortOptions.map((o) => {
        return {
          value: o,
          title: this.getDisplayTitle(o),
        }
      })
    },
  },
  methods: {
    getDisplayTitle(value: string): TranslateResult {
      switch (value as SermonSortOptions) {
        case SermonSortOptions.Oldest:
          return this.$t('Oldest')
        case SermonSortOptions.Title:
          return this.$t('Alphabetical')
        case SermonSortOptions.Updated:
          return this.$t('Recently Updated')
        case SermonSortOptions.LastPlayed:
          return this.$t('Recently Played')
        case SermonSortOptions.Speaker:
          return this.$t('Speaker Name')
        case SermonSortOptions.NewestPublished:
          return this.$t('Newest Published')
        case SermonSortOptions.Newest:
          return this.$t('Newest')
        case SermonSortOptions.Downloads:
          return this.$t('Popular')
        case SermonSortOptions.Relevance:
          return this.$t('Relevance')
        case SermonSortOptions.Bible:
          return this.$t('Bible Reference')
        default:
          console.debug(
            `Translated title missing for ${value} in Sermon Sort Dropdown`
          )
          return value
      }
    },
  },
})
