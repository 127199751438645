
import Vue, { PropType } from 'vue'
import { SermonApi, SermonRequestOptions } from '~/apiclient/apisermons'
import { getListWithPlaceholders } from '~/assets/ts/utils/lists'
import { Sermon } from '~/models/sermon'
import DelayRender from '~/components/_general/DelayRender.vue'
import HorizontalSermonList from '~/components/_general/HorizontalSermonList.vue'
import { ValuesMatch } from '~/assets/ts/utils/validation'

export default Vue.extend({
  name: 'HorizontalFilteredSermonList',
  components: { HorizontalSermonList, DelayRender },
  props: {
    sermonOptions: {
      type: Object as PropType<SermonRequestOptions>,
      default: undefined,
    },
    delayRender: {
      type: Boolean,
    },
    async: {
      type: Boolean,
    },
    pageSize: {
      type: Number,
      default: 12,
    },
  },
  data() {
    return {
      sermonPojos: [] as SermonApi[],
    }
  },
  async fetch() {
    if (this.delayRender) return
    if (this.async) return
    await this.getSermons()
  },
  computed: {
    sermons(): (Sermon | undefined)[] {
      const list = this.sermonPojos.map((s) => new Sermon(s))
      return getListWithPlaceholders(list, this.pageSize)
    },
  },
  watch: {
    sermonOptions: {
      handler(
        newOptions: SermonRequestOptions | undefined,
        oldOptions: SermonRequestOptions | undefined
      ) {
        if (ValuesMatch(newOptions, oldOptions)) return
        this.getSermons()
      },
    },
  },
  async mounted() {
    if (this.delayRender) return
    if (!this.async) return
    await this.getSermons()
  },
  methods: {
    async getSermons() {
      if (!this.sermonOptions) return
      const { results } = await this.$apiClient.getFilteredSermonList({
        pageSize: this.pageSize,
        ...this.sermonOptions,
      })
      this.sermonPojos = results
    },
  },
})
