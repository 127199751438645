
import Vue, { PropType } from 'vue'
import { QuoteApi } from '~/apiclient/apidevotional'
import SiteFeaturedItemLayout from '~/components/site/featured/ItemLayout.vue'
import LoadingElement from '~/components/_general/LoadingElement.vue'

export default Vue.extend({
  name: 'SiteFeaturedQuoteElement',
  components: { LoadingElement, SiteFeaturedItemLayout },
  props: {
    quote: {
      type: Object as PropType<QuoteApi>,
      default: undefined,
    },
  },
  computed: {
    quoteText(): string {
      return this.quote ? this.quote.quote : ''
    },
    author(): string {
      return this.quote ? this.quote.author : ''
    },
  },
})
