
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import SiteFeaturedItemLayout from '~/components/site/featured/ItemLayout.vue'
import { SaIconsType } from '~/assets/ts/types/icons'
import { abbreviateNumber } from '~/assets/ts/utils/math'

export default Vue.extend({
  name: 'SiteRecentStat',
  components: { SiteFeaturedItemLayout },
  props: {
    total: {
      type: Number,
      required: true,
    },
    recentTotal: {
      type: Number,
      required: true,
    },
    statTypeString: {
      type: String as PropType<TranslateResult>,
      required: true,
    },
    icon: {
      type: String as PropType<SaIconsType>,
      required: true,
    },
    isAddedAction: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    totalString(): string {
      if (this.total >= 10000000) {
        return abbreviateNumber(this.total, 0)
      } else {
        return this.total.toLocaleString()
      }
    },
    actionString(): TranslateResult {
      if (this.isAddedAction) {
        return this.$t('{n} added in the past 30 days', {
          n: abbreviateNumber(this.recentTotal),
        })
      } else {
        return this.$t('{n} plays in the past 30 days', {
          n: abbreviateNumber(this.recentTotal),
        })
      }
    },
  },
})
