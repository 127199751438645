
import Vue, { PropType } from 'vue'
import { Sermon } from '~/models/sermon'
import SermonElement from '~/components/_general/SermonElement.vue'
import { SermonDisplay, SermonMetadataExtendedInfo } from '~/assets/ts/enums'
import HorizontalScroll from '~/components/_general/HorizontalScroll.vue'

export default Vue.extend({
  name: 'HorizontalSermonList',
  components: { HorizontalScroll, SermonElement },
  props: {
    sermons: {
      type: Array as PropType<Sermon[]>,
      default() {
        return []
      },
    },
  },
  computed: {
    SermonMetadataExtendedInfo() {
      return SermonMetadataExtendedInfo
    },
    SermonDisplay() {
      return SermonDisplay
    },
  },
})
