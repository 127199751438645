
import Vue from 'vue'
import SiteTitledSection, {
  SiteTitledSectionProps,
} from '~/components/site/TitledSection.vue'
import SiteFilteredSermonList, {
  SiteSermonFilteredListProps,
} from '~/components/site/FilteredSermonList.vue'

export default Vue.extend({
  name: 'SiteSermonsFilteredListWithTitle',
  components: { SiteTitledSection, SiteFilteredSermonList },
  props: {
    ...SiteTitledSectionProps,
    ...SiteSermonFilteredListProps,
    topBorder: {
      type: Boolean,
      default: false,
    },
    addMargin: {
      type: Boolean,
      default: false,
    },
  },
})
